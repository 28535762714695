import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { CommonModule } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingComponent } from './loading/loading.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuModule } from 'primeng/menu';
import { MenuComponent } from './menu/menu.component';
import { MenuEventDirective } from './directive/menu-event-directive';
import { LoginComponent } from './login/login.component';

@NgModule({
    imports: [
        FormsModule,
        DynamicDialogModule,
        InputTextModule,
        ButtonModule,
        DialogModule,
        ToastModule,
        ReactiveFormsModule,
        CommonModule,
        TabViewModule,
        FontAwesomeModule,
        NgbModule,
        MenuModule,
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
        LoadingComponent,
        ErrorDialogComponent,
        MenuComponent,
        MenuEventDirective,
        LoginComponent,
    ],
    exports: [FooterComponent, HeaderComponent, LoadingComponent, ErrorDialogComponent, MenuComponent],
    providers: [],
    bootstrap: [],
})
export class CommonsModule {}
