import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Observable, of, throwError } from 'rxjs';
import {
	catchError,
	mergeMap,
	retryWhen,
	switchMap,
	take,
} from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	public intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next
			.handle(
				this.addAuthenticationToken(
					req,
					this.authService.accessToken.getValue()
				)
			)
	}

	addAuthenticationToken(request, token: string) {
		const accessToken = token;

		if (!accessToken) {
			return request;
		}

		// We clone the request, because the original request is immutable
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
	}
}
